import { useStore } from "vuex";
import type { CashUpFront } from "~/components/ecommerce/GizmoCheckoutChoosePaymentType/checkout-choose-payment-type";

const state: {
	amountIncrements: number;
	cashUpFrontInfo?: CashUpFront;
} = {
	amountIncrements: 1000,
	cashUpFrontInfo: undefined,
};

export default function (cashUpFrontInfo?: CashUpFront) {
	const { fullPricePrices } = useGetPrices();
	const { totalEstimate } = useTradeIn();

	if (cashUpFrontInfo) {
		state.cashUpFrontInfo = cashUpFrontInfo;
	}

	const store = useStore();
	const currentTerminal = computed(() => store.getters["checkout/currentTerminal"]);
	const downPaymentProducts = computed(() => {
		return currentTerminal?.value?.metadata?.prices?.downPaymentProducts;
	});

	const amountUpFront = ref(0);

	const isAmountUpFrontEligible = computed(() => {
		const totalPrice = fullPricePrices.value.price;

		//this is because the minimum amount that needs to be left on the agreeemnt is 468
		return (
			downPaymentProducts?.value?.length > 0 &&
			state?.cashUpFrontInfo?.enabled &&
			totalPrice - state?.cashUpFrontInfo?.amountIncrements - totalEstimate.value?.total - 468 >
				state?.cashUpFrontInfo?.minimumAmountRemaining
		);
	});

	const maxAmountUpFront = computed(() => {
		const floorToIncrement = (value, increment) => Math.floor(value / increment) * increment;

		const maxAmount = state.cashUpFrontInfo?.maxAmount;
		const totalPrice = fullPricePrices.value.price;

		//this is because the minimum amount that needs to be left on the agreeemnt is 468
		const adjustedTotalPrice =
			totalPrice - state.cashUpFrontInfo?.minimumAmountRemaining - totalEstimate.value?.total - 468;
		const amountAvailableToPayUpFront = floorToIncrement(adjustedTotalPrice, state.cashUpFrontInfo?.amountIncrements);

		return Math.min(amountAvailableToPayUpFront, maxAmount);
	});

	const setAmountUpFront = (value: number) => {
		amountUpFront.value = value;
	};

	setAmountUpFront(currentTerminal.value?.metadata?.downPaymentPlan?.amountUpFront ?? 0);

	watch(amountUpFront, async (newValue) => {
		if (currentTerminal.value) {
			await store.dispatch("shoppingCart/setMetadata", {
				downPaymentPlan: { ...currentTerminal.value?.metadata?.downPaymentPlan, amountUpFront: newValue },
			});
		}
	});

	return {
		isAmountUpFrontEligible,
		amountUpFront,
		maxAmountUpFront,
		amountIncrements: state.amountIncrements,
		cashUpFrontInfo: state.cashUpFrontInfo,
		setAmountUpFront,
	};
}
